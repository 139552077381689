export function isLatestElementOfList(index, list){
    return index !== (list.length - 1)
}

export function sortListAlphabeticallyByParameter(list, parameter) {
    return list.sort((a, b) => {
        if (a[parameter].toLowerCase() < b[parameter].toLowerCase()) {
            return -1
        } else if (a[parameter].toLowerCase() > b[parameter].toLowerCase()) {
            return 1
        } else {
            return 0
        }
    })
}

export function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}
  
export function differenceLeft(a, b) {
    return a.filter(x => !b.includes(x));
}