<template>
  <v-card tile flat>
    <v-list-item two-line>
      <Avatar :initials="formatUserToInitials" :userId="user.id" :SASKey="SASKey" :avatar="avatar"/>
      <v-list-item-content class="pl-4">
        <v-list-item-title class="body-1 font-weight-medium">{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="ma-0">
        <v-btn icon :color="user.active ? 'primary':'red'"
               fab @click="toggleUserActive">
          <v-icon v-if="user.active">mdi-toggle-switch</v-icon>
          <v-icon v-else>mdi-toggle-switch-off</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action class="ma-0">
        <div v-if="withDelete">
          <v-btn
              icon
              color="primary"
              fab
              dark
              @click="deleteUser">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
        <div v-else>
          <v-btn
              icon
              color="primary"
              fab
              dark
              @click="modifyUser">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import Avatar from "@/components/shared/Avatar.vue";
import {formatUserToInitials} from "@/shared/utils/userUtils";

export default {
  name: 'UserListComponent',
  components: {Avatar},
  props: {
    user: Object,
    withDelete: {
      type: Boolean,
      default: false
    },
  SASKey:{
      type: String,
      default: null,
      required: false,
    },
    avatar:{
      type: String,
      default: null,
      required: false,
    }
  },
  methods: {
    modifyUser() {
      this.$emit('modify', this.user)
    },
    toggleUserActive() {
      this.user.active = !this.user.active
        let payload = {
          teamId: this.$route.params.teamId,
          userId: this.user.id
        }
        this.$store.dispatch('teamsModule/toggleUserActiveInTeam', payload)
    },
    deleteUser() {
      this.$emit('delete', this.user)
    },
  },
  computed: {
    formatUserToInitials() {
      return formatUserToInitials(this.user)
    }
  }
}
</script>