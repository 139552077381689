<template>
  <PromiseContainer :promises.sync="promises">
    <InputMember v-model="inputMemberDialog" :team="team" :key="enterMember" @close="closeInputMember" />
    <InputLeader v-model="inputLeaderDialog" :team="team" :key="enterLeader" @close="closeInputLeader" />
    <div>
      <v-alert type="error" v-if="error" dismissible dense>
        {{ error }}
      </v-alert>
      <v-card id="listcard" flat>
        <v-card-title>
          {{ team.teamName }}
          <v-spacer></v-spacer>
          <v-btn elevation="0" class="ma-0 pa-0" small fab color="primary" @click="openClientOverview">
            <v-icon>
              mdi-view-list
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-list>
          <v-card-title class="pb-0">Leiders
            <v-spacer></v-spacer>
            <v-btn icon color="primary" fab dark @click="inputLeader">
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <UserListComponent v-for="leader in leaders" :key="leader.id" justify="space-between" @delete="deleteLeader"
            :user="leader" :withDelete="true" :SASKey="SASKey" :avatar="leader.avatar"/>
        </v-list>
        <v-list>
          <v-card-title class="pb-0">Leden
            <v-spacer></v-spacer>
            <v-btn icon color="primary" fab dark @click="inputMember">
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <UserListComponent v-for="user in users" :key="user.email" justify="space-between" @delete="deleteMember"
            :user="user" :withDelete="true" :SASKey="SASKey" :avatar="user.avatar"/>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close" color="primary">Terug naar team overzicht</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </div>
  </PromiseContainer>
</template>

<script>
import UserListComponent from "@/components/useroverview/UserListComponent";
import InputMember from "@/components/teamoverview/InputMember"
import InputLeader from "@/components/teamoverview/InputLeader"
import RouteNames from "@/router/RouteNames";
import PromiseContainer from "@/components/shared/PromiseContainer.vue"

export default {
  name: "MemberOverview",
  components: {
    UserListComponent,
    InputMember,
    InputLeader,
    PromiseContainer
  },
  props: {
    teamId: String
  },
  data() {
    return {
      team: {},
      user: null,
      error: null,
      users: {},
      modifyUserDialog: false,
      inputMemberDialog: false,
      enterMember: null,
      leader: null,
      leaders: {},
      inputLeaderDialog: false,
      enterLeader: null,
      SASKey: null,
      promises: [this.fetchMembersAndLeaders(), this.$store.dispatch("fileExportModule/fetchSASKey")
          .then(result => this.SASKey = result)]
    }
  },
  methods: {
    fetchMembersAndLeaders() {
      return this.$store.dispatch('teamsModule/fetchTeam', this.teamId)
          .then(result => {
            this.team = result
            this.promises.push(
                this.$store.dispatch('teamsModule/fetchUsersFromTeam', this.team.id).then(result => this.users = result),
                this.$store.dispatch('teamsModule/fetchLeadersFromTeam', this.team.id).then(result => this.leaders = result)
            )
          })
    },
    closeInputMember() {
      this.enterMember = null
      this.inputMemberDialog = false
      this.promises.push(this.fetchMembersAndLeaders())
    },
    inputMember() {
      this.promises.push(
          this.$store.dispatch('usersModule/fetchUsersFromOrganizationNotInTeam', this.team)
              .then(() => {
                this.enterMember = "member"
                this.inputMemberDialog = true
              })
      )
    },
    inputLeader() {
      this.promises.push(
          this.$store.dispatch('usersModule/fetchUsersFromOrganizationNotInTeam', this.team)
              .then(() => {
                this.enterLeader = "leader"
                this.inputLeaderDialog = true
              })
      )
    },
    deleteLeader(user) {
      const data = {
        teamId: this.teamId,
        userId: user.id
      }

      this.promises.push(this.$store.dispatch('teamsModule/removeLeader', data).then(() => this.promises.push(this.fetchMembersAndLeaders())))
    },
    closeInputLeader() {
      this.enterLeader = null
      this.inputLeaderDialog = false
      this.promises.push(this.fetchMembersAndLeaders())
    },
    deleteMember(user) {
      const data = {
        teamId: this.teamId,
        userId: user.id
      }
      this.promises.push(this.$store.dispatch('teamsModule/removeUser', data).then(() => this.promises.push(this.fetchMembersAndLeaders())))
    },
    openClientOverview() {
      this.$router.push({name: RouteNames.TEAM_CLIENT_OVERVIEW, params: {teamId: this.team.id}})
    },
    close() {
      this.$router.push({name: RouteNames.TEAM_OVERVIEW})
    }
  }
}
</script>