<template>
  <v-avatar v-if="!getAvatar || !SASKey" size="40" class="text-subtitle-1 justify-center"
            :color="avatarBackgroundColor">
    {{ initials }}
  </v-avatar>
  <v-avatar v-else size="40" class="text-subtitle-1 justify-center">
    <v-img :src="getAvatar + '&' + this.SASKey"/>
  </v-avatar>
</template>

<script>
import randomColor from "randomcolor";


export default {
  name: "Avatar",
  components: {
  },
  props: {
    initials: String,
    id:{
      type: String,
      default: null,
      required: false,
    },
    action:{
      type: String,
      default: null,
      required: false,
    },
    getter:{
      type: String,
      default: null,
      required: false,
    },
    SASKey: {
      type: String,
      required: false
    },
    avatar:{
      type: String,
      required: false,
      default: null,
    }
  },
  computed: {
    avatarBackgroundColor() {
      return randomColor({
        seed: this.initials,
        luminosity: 'light'
      })
    },
    getAvatar() {
      if(this.avatar){
        return this.avatar
      }else if (this.getter && this.id) {
        return this.$store.getters[this.getter](this.id)
      }
      return null
    }
  },
  created() {
    if(!this.avatar && this.action && this.id){
      this.$store.dispatch(this.action, this.id)
    }
  }
}
</script>
