<template>
  <v-dialog v-model="show" max-width="500px" :fullscreen="$vuetify.breakpoint.xsOnly"
            transition="dialog-bottom-transition" persistent>
    <v-card>
      <v-toolbar dark dense>
        Nieuwe leider aan team {{ team.teamName }} toevoegen
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="valid">
          <CustomUserSelect v-model="user" :cols="'12'" class="mt-2" required/>
          <v-row justify="end">
            <v-col cols="auto">
              <v-btn type="button" color="secondary" :disabled="!valid" @click="submit">Toevoegen</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomUserSelect from "@/components/shared/CustomUserSelect.vue";

export default {
  name: "InputLeader",
  components: {
    CustomUserSelect,
  },
  props: {
    value: Boolean,
    team: Object
  },
  data() {
    return {
      valid: false,
      user: null,
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submit() {
      const data = {
        teamId: this.team.id,
        userId: this.user.id
      }
      this.$store.dispatch('teamsModule/addLeader', data).then(() => this.close())
    }
  },
}
</script>
